<template>
    <div :key="componentKey">
        <div v-if="can_create">
            <time-create abteil="user" :abteil_id="abteil_id" @stored="loadData" @errors="updateErrors"
                         @toggleStopWatch="toggleStopWatch" :old_bis="oldBis" :all_projects="projects" :all_codes="workcodes"
                         v-show="!stopWatch" :key="'time-create-key-' + timeCreateKey">
            </time-create>
            <stop-watch v-if="stopWatch" @toggleStopWatch="toggleStopWatch" :projects="projects"
                        :user_id="abteil_id"
                        :workcodes="workcodes">
            </stop-watch>
            <hr class="my-3">
        </div>
        <loading-progress v-if="progress < 1.0" :progress="progress" :indeterminate="true"
                          :counter-clockwise="false"
                          :hide-background="true" fill-duration="3" shape="square" size="64"/>
        <div v-show="progress === 1.0" v-if="timeRows.length > 0">
            <div id="scrollArea" class="my-2">
                <div v-for="(timeRow, index) in timeRows" :key="timeRow.id">
                    <time-row :timeData="timeRow" :abteil="abteil" :abteil_id="abteil_id" :projects="projects"
                              :workcodes="workcodes" @errors="updateErrors" :can_delete="can_delete"
                              :can_edit="can_edit" :can_view_mandat="can_view_mandat"
                              v-bind:class="(index % 2 === 0) ? 'bg-white' : 'bg-light'">
                    </time-row>
                </div>
                <time-drop v-on:delete-row="del" id="drop_modal"></time-drop>
            </div>
            <div class="row my-3">
                <div class="col-lg-6">
                    <nav>
                        <ul class="pagination">
                            <li aria-label="« Zurück" class="page-item">
                                <button class="page-link" @click="switchPageDown()">‹</button>
                            </li>
                            <li v-for="nav in navigation" v-bind:class="[nav.active ? 'active' : '', 'page-item']">
                                <button @click="switchPage(nav.page)" class="page-link">{{nav.page}}</button>
                            </li>
                            <li class="page-item">
                                <button class="page-link" @click="switchPageUp()">›</button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-lg-5 text-right">
                    Es werden die Einträge {{start}} bis {{ende}} angezeigt von total {{total}}.
                </div>
                <div class="col-lg-1 text-right">
                    <a onclick="scrollUp()" title="hoch scrollen" href="#">
                        <i class="fas fa-angle-up"></i>
                    </a>
                    <a onclick="scrollBottom()" title="runter scrollen" href="#">
                        <i class="fas fa-angle-down"></i>
                    </a>
                </div>
            </div>
            <div class="alert alert-danger" v-show="showErrors">
                <h4>Fehler</h4>
                <ul v-for="(error, key) in errors">
                    <li>{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import timetracker from "../timetracker";
    import TimeCreate from "./TimeCreate";
    import StopWatch from "./StopWatch";
    import TimeDrop from "./TimeDrop";
    import TimeRow from "./TimeRow";

    export default {
        props: {
            abteil: {type: String},
            abteil_id: {type: String},
            request: {type: String},
            can_create_str: {type: String},
            can_delete_str: {type: String},
            can_edit_str: {type: String},
            can_view_mandat_str: {type: String},
            filter_project_id: {type: String},
            anzeige_von: {type: String},
            anzeige_bis: {type: String},
        },

        data() {
            return {
                componentKey: 0,
                projects: [],
                workcodes: [],
                timeRows: {},
                limit: 25,
                start: 1,
                ende: 1,
                total: 1,
                navigation: {},
                pageNr: 1,
                progress: 0.0,
                oldBis: '07:30',
                errors: {},
                showErrors: false,
                stopWatch: false,
                can_create: this.can_create_str === "1",
                can_edit: this.can_edit_str === "1",
                can_delete: this.can_delete_str === "1",
                can_view_mandat: this.can_view_mandat_str === "1",
                timeCreateKey: 0
            }
        },
        methods: {
            async loadData() {
                await axios.get('/api/v2/mandate/').then(response => {
                    this.projects = response.data.data.projects;
                });

                await axios.get('/api/codes/').then(response => {
                    this.workcodes = response.data;
                });

                this.timeCreateKey++;
                await this.loadTimeRows();

                this.start = 1 + (this.pageNr - 1) * this.limit;
                this.ende = Math.min(this.pageNr * this.limit, this.start + this.timeRows.length - 1);

                this.navigation = this.setupNavigation();
                this.progress = 1.0;
                this.$emit('data:loaded');
            },
            async loadTimeRows() {
                let url_param = "";

                if (this.pageNr > 0) {
                    url_param += "&page=" + this.pageNr;
                }

                if (typeof this.filter_project_id !== 'undefined' && Number(this.filter_project_id) > 0) {
                    url_param += "&filter_mandat_id=" + Number(this.filter_project_id);
                }

                if (typeof this.anzeige_von !== 'undefined') {
                    url_param += "&von=" + this.anzeige_von;
                }

                if (typeof this.anzeige_bis !== 'undefined') {
                    url_param += "&bis=" + this.anzeige_bis;
                }

                await axios.get('/api/v2/zeiterfassung/' + this.abteil + '/'
                    + this.abteil_id + '?limit=' + this.limit + url_param).then(response => {
                    this.timeRows = response.data.data.times;
                    this.total = response.data.data.count;

                    // check if even days are existing
                    if (Object.keys(this.timeRows).length > 0) {

                        // if booking is on same day set limits for create rows (compares YYYY-MM-DD)
                        if (this.timeRows[0].tag === timetracker.getNow().date) {
                            this.oldBis = timetracker.calcBis(this.timeRows[0].von, this.timeRows[0].dauer);

                            // set limits for create row
                            let von_create = jQuery('#von_create');
                            let bis_create = jQuery('#bis_create');

                            von_create.val(this.oldBis);
                            von_create.timepicker('option', 'minTime', this.oldBis);
                            bis_create.timepicker('option', 'minTime', this.oldBis);
                        }
                    }
                });
            },
            updateErrors(error) {
                this.errors = error;
                this.showErrors = Object.keys(error).length > 0;
            },

            forceRerender() {
                this.componentKey += 1;
            },

            toggleStopWatch(toggled) {
                this.stopWatch = toggled;
                if (!toggled) {
                    this.loadTimeRows();
                }
            },

            /**
             * @purpose delete zeiteintrag
             * @param id
             * @author SamuelSchwegler
             */
            async del(id) {
                jQuery('#drop_modal').modal('hide');

                let comp = this;

                await axios.delete(`/api/zeiterfassung/${id}`).then(response => {
                    if (response.status === 200) {
                        this.loadTimeRows();

                        flash('Löschen erfolgreich', 'success');
                    }
                }).catch(error => {
                    comp.updateErrors(error.response.data);
                    flash('Fehler beim Löschen', 'danger');
                });
            },

            /**
             * @purpose handling of pagination
             * @changes this.pageNr
             * @author SamuelSchwegler
             */
            setupNavigation() {
                let navigation = [];
                let id = 1;
                const max_pageNr = Math.ceil(this.total / this.limit);
                let dotsBefore = false;
                let dotsAfter = false;

                while (id <= max_pageNr) {
                    if (id === 1 || id === max_pageNr || (id >= (this.pageNr - 2) && id <= (this.pageNr + 2))) {
                        navigation.push({page: id, active: this.pageNr === id});
                    } else if (this.pageNr > 3 && !dotsBefore) {
                        navigation.push({page: '...', active: false});
                        dotsBefore = true;
                    } else if (this.pageNr < max_pageNr - 2 && id > this.pageNr && !dotsAfter) {
                        navigation.push({page: '...', active: false});
                        dotsAfter = true;
                    }
                    id += 1;
                }
                return navigation;
            },

            switchPage(page) {
                if (typeof page === "number") {
                    this.progress = 0.0;
                    this.pageNr = page;
                    this.loadData();
                }
            },
            switchPageUp() {
                let maxpage = Math.ceil(this.total / this.limit);
                if (this.pageNr < maxpage) {
                    this.switchPage(Math.min(this.pageNr + 1, maxpage));
                }
            },
            switchPageDown() {
                if (this.pageNr > 1) {
                    this.switchPage(Math.max(this.pageNr - 1, 1));
                }
            },
        },
        created() {
            this.loadData();
        },
        mounted() {
            $(document).on('keydown','.multiselect__input', function (event) {
                var code = event.keyCode || event.which;
                if (code === 9) {
                    jQuery.tabNext();
                    var e = jQuery.Event('click');
                    jQuery(event.target).parent().siblings().find('.multiselect__option--highlight:not(.multiselect__option--selected)').trigger(e);
                    event.preventDefault();
                }
            });
        },
        components: {
            TimeCreate, StopWatch, TimeRow, TimeDrop
        }
    }
</script>
,
