<template>
    <div class="row mx-0" :key="componentKey">
        <div class="col-lg-4 py-1">
            <div class="row">
                <div class="col-3 px-1">
                    <input id="tag_neu" type="text" v-model="tag" class="form-control date-sm">
                </div>
                <div class="col-9">
                    <div class="row">
                        <div class="col-3 px-1">
                            <input id="von_create" v-model="von" type="text"
                                   class="input-time form-control text-center"/>
                        </div>
                        <div class="col-3 px-1">
                            <input id="bis_create" v-model="bis" type="text"
                                   class="input-time form-control text-center"/>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="text" name="dauer" v-model="dauer" tabindex="-1" readonly="readonly"
                                       disabled="disabled" class="dauer form-control text-center px-1 " min="0">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 px-1">
                            <div class="input-group input-duration">
                                <input type="number" min="0" step="1" v-model="unverrechenbar" tabindex="-1"
                                       @change="create" :max="dauer"
                                       class="dauer form-control text-center px-1">
                                <div class="input-group-append">
                                    <span class="input-group-text">m</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 py-1">
            <div class="row">
                <div class="pl-1 col-5 pr-1">
                    <multiselect @input="create" @select="selectAndFocus($event, 'code_create')" v-model="selected_project" :options="projects" :close-on-select="true" id="project-select"
                                 :custom-label="projectLabel" required label="id" track-by="id" :show-labels="false"
                                 selectLabel="" :allow-empty="false" :optionsLimit="50" :key="'select-projects-' + selectProjectKey"
                                 placeholder="bitte wählen">
                        <span slot="noResult">Nichts gefunden, nur Aktive werden angezeigt.</span>
                    </multiselect>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-3 px-1">
                            <multiselect @input="create"  v-model="selected_code" :options="workcodes" id="code_create"
                                         :custom-label="codeLabel" required label="id" track-by="id" :close-on-select="true"
                                         selectLabel="" :allow-empty="false" :optionsLimit="30" placeholder="wählen">
                            </multiselect>
                        </div>
                        <div class="col-9 px-1">
                            <input type="text" @blur="create" name="kommentar" @keyup.enter="executeCreate"
                                   class="form-control px-1"
                                   v-model="kommentar" id="kommentar_create">
                        </div>
                    </div>
                </div>
                <div class="col-1 px-1">
                    <button @click="startStoppuhr" id="stoppuhr-start" data-toggle="tooltip" data-placement="bottom"
                            class="btn btn-primary btn-block" data-original-title="Zeit mit Stoppuhr erfassen">
                        <i class="fas fa-stopwatch"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import timetracker from "../timetracker";
    import Multiselect from "vue-multiselect";

    export default {
        components: {
            Multiselect
        },
        name: 'time-create',
        props: ['abteil_id', 'abteil', 'old_bis', 'all_projects', 'all_codes'],

        data() {
            return {
                tag: timetracker.getNow().dateSm,
                von: timetracker.getNow().time,
                dauer: 0,
                unverrechenbar: 0,
                bis: '',
                componentKey: 0,
                drop_modal_id: '',
                mandat_id: 0,
                user_id: 0,
                code_id: 0,
                kommentar: "",
                projects: [{
                    id: 0,
                    bezeichnung: 0,
                    klient: {
                        id: 0,
                        name: ""
                    },
                    status: 0,
                }],
                workcodes: [{
                    id: 0,
                    code_kurz: 0,
                    comment_required: 0,
                }],
                selected_project: null,
                selected_code: null,
                selectProjectKey: 0,
            }
        },
        methods: {
            forceRenderer() {
                this.componentKey += 1;
            },
            loadData() {
                if (this.abteil === "user") {
                    this.user_id = this.abteil_id;
                } else if (this.abteil === "mandat") {
                    this.mandat_id = this.abteil_id;
                }

                if (this.old_bis !== null) {
                    jQuery('#von_create').timepicker('option', 'minTime', this.old_bis);
                    jQuery('#bis_create').timepicker('option', 'minTime', this.von);
                }

                this.workcodes = this.all_codes;
                this.projects = this.all_projects.filter(project => project.status === 0);
            },
            /**
             * Vorbereitung der Erstellung
             * @changed v1.5.4 - bugfix zu schnelles springen
             */
            create() {
                this.von = timetracker.fixTime(this.von);
                this.bis = timetracker.fixTime(this.bis);
                this.dauer = timetracker.calcDauer(this.von, this.bis);
                this.tag = timetracker.fixDate(this.tag);

                let loc = this;
                let comment_create = jQuery('#kommentar_create');

                if (comment_create.is(":focus") || ((this.selected_code !== null) && this.selected_code.comment_required === "1" && comment_create.val() === "")) {
                    return;
                }

                setTimeout(function () {
                    // check ob comment create is focussed
                    if (!comment_create.is(":focus")) {
                        loc.executeCreate();
                    }
                }, 1250);
            },
            async executeCreate() {
                let errors = timetracker.checkRules(this);
                if (Object.keys(errors).length > 0) {
                    return;
                }
                await axios.post(`/api/zeiterfassung/`, {
                    'tag': this.tag,
                    'von': this.von,
                    'bis': this.bis,
                    'unverrechenbar': this.unverrechenbar,
                    'mandat_id': this.selected_project.id,
                    'user_id': this.user_id,
                    'code_id': this.selected_code.id,
                    'dauer': this.dauer,
                    'kommentar': this.kommentar,
                }).then(response => {
                    if (response.status === 200) {
                        flash('Eintrag erfolgreich', 'success');

                        // prepare row for next entry
                        this.resetRow();

                        // force reload content
                        this.$emit('stored');
                    }
                }).catch(error => {
                    this.$emit('errors', error.response.data);
                    flash('Fehler beim Eintrag', 'danger');
                });
            },
            resetRow() {
                //clear row / reset
                this.selected_code = null;
                this.selected_project = null;
                this.von = this.bis;
                this.bis = '';
                this.dauer = 0;
                this.unverrechenbar = 0;
                this.kommentar = "";
                this.tag = timetracker.getNow().dateSm; //allways new entries for today

                jQuery('#von_create').timepicker('option', 'minTime', this.old_bis);
                jQuery('#bis_create').timepicker('option', 'minTime', this.von);
             },

            startStoppuhr() {
                this.$emit('toggleStopWatch', true);

                // Enable navigation prompt -> asks if you are willing to leave page
                window.onbeforeunload = function () {
                    return true;
                };
            },
            dataLoaded: function() {
                //jQuery('#bis_create').focus();
            },
            /**
             * @purpose for multi select
             * @returns {string}
             */
            projectLabel: timetracker.projectLabel,
            codeLabel: timetracker.codeLabel,
            selectAndFocus: function (value, next_id) {
                jQuery('#' + next_id).focus();
            },
        },
        created() {
            this.$parent.$on('data:loaded', this.dataLoaded);
        },
        mounted() {
            this.loadData();
            let vm = this;

            let von_create = jQuery('#von_create');
            let bis_create = jQuery('#bis_create');

            von_create.timepicker({
                'timeFormat': 'H:i',
                'step': 15
            }).on('changeTime', function () {
                vm.von = von_create.val();
                vm.bis = bis_create.val();
                vm.create();

                bis_create.timepicker('option', 'minTime', vm.von);
            });

            bis_create.timepicker("remove");
            bis_create.timepicker({
                'timeFormat': 'H:i',
                'step': 15,
            }).on('changeTime', function () {
                vm.bis = bis_create.val();
                vm.von = von_create.val();
                vm.create();
            });

            if(vm.von !== null && vm.von.length > 0) {
                bis_create.timepicker('option', 'minTime', vm.von);
            }

            jQuery(".date-sm").datepicker({
                beforeShow: function (input, inst) {
                    jQuery(document).off('focusin.bs.modal');
                },
                onClose: function () {
                    jQuery(document).on('focusin.bs.modal');
                },
                dateFormat: 'dd.mm.y',
                changeMonth: true,
                changeYear: true,
                yearRange: "-5:+20", // last hundred years
                firstDay: 1, // Start with Monday
                monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
                dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                onSelect: function (dateText) {
                    vm.tag = dateText;
                    vm.create()
                }
            });
        },
        watch: {
            old_bis: function (val) {
                this.von = val;
            }
        },
    }
</script>
