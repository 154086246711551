require('./bootstrap');

import Vue from 'vue';
import VueProgress from 'vue-progress-path'

Vue.use(VueProgress);

window.events = new Vue();
window.flash = function (message, state) {
    window.events.$emit('flash', {message, state});
};

Vue.component('time-table', require('./components/TimeTable.vue').default);
Vue.component('flash', require('./components/Flash.vue').default);

if (process.env.APP_DEBUG) {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
}

const app = new Vue({
    el: '#vue-app',
});


