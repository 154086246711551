<template>
    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title">Eintrag Löschen?<!--{{__('Delete entry?')}}--></h4>
                </div>

                <div class="modal-body">
                    <p>
                        Sind Sie sicher, dass Sie den Eintrag löschen wollen?
                        <!--{{__('Are you sure about deleting this time entry?')}}-->
                    </p>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-default"
                            data-dismiss="modal">
                        Abbrechen
                        <!--{{__('cancel')}}-->
                    </button>
                    <button type="submit" name="löschen-ok" @click="$emit('delete-row', idToDelete)"
                            class="btn btn-danger btn-ok">
                        Löschen
                        <!--{{__('drop')}} -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {

        data() {
            return {
                idToDelete: 0,
            }
        },
        methods: {
        },
        mounted() {
            jQuery('#drop_modal').on('show.bs.modal', function(e) {
                //get data-id attribute of the clicked element
                this.idToDelete =  jQuery(e.relatedTarget).data('time-id');
            }.bind(this));
        },
    }
</script>
